import axios from '@/libs/axios'

export default class CentralJmrsRotacionesArts {
    
    getCentralJmrsRotacionesArts(idSucursal, idAlmacen) {
        return axios.get(`/CentralJmrsRotacionesArts/GetCustomResultCentralJmrsRotaciones/${idSucursal}/${idAlmacen}`);        
    }

    GetCentralJmrsRotacionesArtsByAlmaceIdAndSucursal(AlmacenId, SucursalId) {
        return axios.get(`/CentralJmrsRotacionesArts/GetCentralJmrsRotacionesArtsByAlmaceIdAndSucursal/${AlmacenId}/${SucursalId}`)
    }

    CalculateRotations(payload) {
        return axios.get(`/CentralJmrsRotacionesArts/CalcularRotaciones`, {params: payload})
    }
}

<template>
  <b-row v-if="$route.meta.breadcrumb || $route.meta.pageTitle" class="content-header">

    <!-- Content Left -->
    <b-col class="content-header-left mb-2" cols="12" md="9">
      <b-row class="breadcrumbs-top">
        <b-col cols="12">
          <h2 class="content-header-title float-left pr-1 mb-0">
            {{ $route.meta.pageTitle }}
          </h2>
          <div class="breadcrumb-wrapper">
            <!-- <b-breadcrumb>
              <b-breadcrumb-item to="/">
                <feather-icon icon="HomeIcon" size="16" class="align-text-top" />
              </b-breadcrumb-item>
              <b-breadcrumb-item v-for="item in $route.meta.breadcrumb" :key="item.text" :active="item.active"
                :to="item.to">
                {{ item.text }}
              </b-breadcrumb-item>
            </b-breadcrumb> -->

            <b-breadcrumb>
              <b-breadcrumb-item>
                <feather-icon icon="DatabaseIcon" size="16" class="align-text-top" />
              </b-breadcrumb-item>
              <b-breadcrumb-item  v-b-tooltip.hover.top="`Conectado en ${systemConexion.dataSource}`">
                {{ systemConexion.nombre || '--' }}
              </b-breadcrumb-item>              
              <b-breadcrumb-item>
                <feather-icon icon="GitBranchIcon" size="16" class="align-text-top" />
                {{ systemBranchName.nombre || '--' }}
              </b-breadcrumb-item>
            </b-breadcrumb>
            
          </div>
        </b-col>
      </b-row>
    </b-col>

    <!-- Content Right -->
    <b-col class="content-header-right text-md-right d-md-block _d-none mb-1" md="3" cols="12">

      <span v-if="isViewPurchase && lastDatePurchase" class="mr-1">
        <small ref="lastUpdatePurchase"><strong>Últ. Actualización: {{ lastDatePurchase !== '0000-00-00 00:00:00' ? formatDate(lastDatePurchase) : lastDatePurchase }}</strong></small>
      </span>

      <b-dropdown v-if="isViewPurchase" variant="link" no-caret toggle-class="p-0" right>

        <template #button-content>
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="btn-icon">
            <feather-icon icon="SettingsIcon"/>
          </b-button>
        </template>

        <b-dropdown-item @click="isActivePurchazeCustomize = true">
          <feather-icon icon="CheckSquareIcon" size="16" />
          <small> Configurar</small><br>
          Columnas Compras
        </b-dropdown-item>

        <!-- <b-dropdown-item @click="isActiveCalculateParameters = true">
          <feather-icon icon="SlidersIcon" size="16" />
          <small> Configurar</small><br>
          Parámetros de Cálculo
        </b-dropdown-item> -->

        <b-dropdown-item v-if="warehouseId && isCanRecalculateRotations" @click="calculateRotations">
          <span class="text-warning">
            <feather-icon icon="RepeatIcon" size="16" />
            Calcular Rotaciones
          </span>          
        </b-dropdown-item>
       
      </b-dropdown>
    </b-col>
    

    <purchase-customize
      :is-active="isActivePurchazeCustomize"  
      @close="isActivePurchazeCustomize = false" />

    <calculation-parameters  :is-active="isActiveCalculateParameters"  
        @close="isActiveCalculateParameters = false"/>
  </b-row>
</template>

<script>
import {
  BBreadcrumb, BBreadcrumbItem, BRow, BCol, BDropdown, BDropdownItem, BButton, BAlert, BLink,
  BTooltip, VBTooltip,
} from 'bootstrap-vue'
import { ref, onMounted, computed, watch, reactive, defineEmits, onErrorCaptured } from "@vue/composition-api";
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import router from '@/router'
import * as helper from '@/libs/helpers';

import PurchaseCustomize from '@core/components/purchase-customizer/PurchaseCustomize.vue';
import CalculationParameters from '@core/components/purchase-customizer/CalculationParameters.vue';

import CentralJmrsRotacionesArtsProvider from '@/providers/CentralJmrsRotacionesArts'
const CentralJmrsRotacionesArtsResource = new CentralJmrsRotacionesArtsProvider()

export default {
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BButton,
    BAlert,
    BLink,
    BTooltip, VBTooltip,
    
    //
    PurchaseCustomize,
    CalculationParameters,
  },
  computed: {
    lastDatePurchase () {
      return store.getters['auth/getLastUpdatePurchase']
    },
    isRecalculateRotatios() {
      return store.getters['auth/getIsRecalculateRotations']
    },
    isCanRecalculateRotations() {
      return this.canPermissions(12) && this.isControlTotal
    },
    isControlTotal() {
      return store.getters['auth/getConexion']?.control_Total || false;
    }

  }, 

  setup() {

    const isActivePurchazeCustomize = ref(false)
    const isActiveCalculateParameters = ref(false)
    
    const systemConexion = store.getters['auth/getConexion']
    const systemWarehouseName = store.getters['auth/getWarehouse']
    const systemBranchName = store.getters['auth/getBranch']

    const isViewPurchase = computed(() => {
      return store.getters['auth/getCurrentRoute'] === 'apps-purchase'
    })

    const warehouseId = computed(() => {
      return store.getters['auth/getWarehouseId']
    })

    const calculateRotations = async () => {

      store.dispatch('auth/UPDATE_IS_ERROR_ON_RECALCULATE_ROTATION', false)
      const payload = {
        idSucursal: store.getters['auth/getBranch'].sucursal_Id,
        idAlmacen: store.getters['auth/getWarehouseId'],
        rolClaveArtId: 17,
      }     

      try {
        store.dispatch('auth/UPDATE_IS_RECALCULATE_ROTATIONS', true)
        const { data } = await CentralJmrsRotacionesArtsResource.CalculateRotations(payload)
        console.log(data)
        if (data.isSuccesful) {
          if(data.data) {
            store.dispatch('auth/UDPDATE_IS_RECALCULATE_RATION_END', true)
            store.dispatch('auth/UPDATE_LAST_UPDATE_PURCHASE', data.data.fecha_Actualizacion)
          }          
        } else {
          store.dispatch('auth/UPDATE_IS_ERROR_ON_RECALCULATE_ROTATION', true)
          store.dispatch('auth/UDPDATE_IS_RECALCULATE_RATION_END', false)
          store.dispatch('auth/UPDATE_LAST_UPDATE_PURCHASE', '0000-00-00 00:00:00')
        }
      }catch(e) {
        console.error(e)
        store.dispatch('auth/UPDATE_IS_ERROR_ON_RECALCULATE_ROTATION', true)
        store.dispatch('auth/UDPDATE_IS_RECALCULATE_RATION_END', false)
        store.dispatch('auth/UPDATE_IS_RECALCULATE_ROTATIONS', false)
        helper.handleResponseErrors(error)
      }
    }

    const canPermissions = (id) => {
        if (id == 0) return true //only dashboard
        const permission = store.getters['auth/getPermissions']            
        return _.find(permission, (p) => p.permiso_Id === id) ? true : false
    }



    return {
      systemConexion,
      systemWarehouseName,
      systemBranchName,
      isActivePurchazeCustomize,
      isActiveCalculateParameters,      

      //
      warehouseId,
      isViewPurchase,
      calculateRotations,
      canPermissions,
    }
  }
}
</script>

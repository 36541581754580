<template>
  <b-nav-item :disabled="loading" v-if="total > 0" title="Revertir Limites de Créditos">
    <feather-icon
      size="21"
      icon="ClockIcon"
      :badge="total"
      badge-classes="badge-danger"
    />
  </b-nav-item>
</template>

<script>
import { ref, computed } from '@vue/composition-api'
import { BNavItem } from 'bootstrap-vue'
import Swal from "sweetalert2";
import "animate.css";
import * as helper from '@/libs/helpers';

import CustomersProvider from "@/providers/Customers"
const CustomersResource = new CustomersProvider()

export default {
  components: {
    BNavItem,
  },
  created() {
    
    //this.CheckCheckRevertCreditLimit()

    // Establecer un intervalo para llamar a la función cada 30 minutos
    //setInterval(this.CheckCheckRevertCreditLimit, 30 * 60 * 1000) // 30 minutos en milisegundos

  },
  setup() {
    
    const total = ref(0)
    const loading = ref(false)

    const CheckCheckRevertCreditLimit = async () => {
      const { data } = await CustomersResource.CheckCheckRevertCreditLimit()
    }

    const revertCreditLimit = () => {
      Swal.fire({
        title: `¿Desea continuar?`,
        text: `Se ejecutará un proceso para revertir los cambios en los límites de crédito que hayan sido actualizados en las últimas 12 horas.`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Continuar',
        cancelButtonText: 'Cancelar',
        customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
        },
        showClass: {
            popup: "animate__animated animate__flipInX",
        },
        buttonsStyling: false,
        showLoaderOnConfirm: true,
        preConfirm: async () => {
          try {
            loading.value = true
            const { data } = await CustomersResource.revertCreditLimit(12, 0)
            loading.value = false
            if (data.isSuccesful) {
              await CheckCheckRevertCreditLimit()
              helper.success(data.message)
            } else {
              helper.danger(data.success)
            }
          }catch(e) {
            loading.value = false
            helper.handleResponseErrors(e)
          }
        },
        allowOutsideClick: () => !Swal.isLoading(),
      })
    }

    return { 
      total,
      loading,
      CheckCheckRevertCreditLimit,
      revertCreditLimit,
    }
  },
}
</script>

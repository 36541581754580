<template>
    <div>
        <b-modal
                ref="infoModalRef"
                id="modalPurchaseCustomize"
                title="Mostrar / Ocultar"
                no-close-on-backdrop
                hide-footer
                @cancel="isActive = false"
                @hidden="closeModal"
                :visible="isActive"
            >

            <h5>Selecciona las columnas a mostrar en la tabla de compras</h5>

            <b-form-group v-if="setting" class="mt-2" description="Recuerda hacer clic en el botón 'Guardar' para aplicar los cambios">
                <b-form-checkbox
                    v-for="(option, index) in setting.columns.filter(s => s.key !== 'show_details' && s.key !== 'sugerido')" :key="index"
                        v-model="option.visible"
                        name="key">
                    {{ option.description }}
                </b-form-checkbox>
            </b-form-group>

            <div class="text-right mt-2">
                <b-button size="sm" :disabled="loading" @click="updateSettingPurchase" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary"
                    class="mr-2" type="button">
                    Guardar
                </b-button>
                <b-button size="sm" :disabled="loading" v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="button"
                    variant="outline-secondary" @click="closeModal">
                    Cancelar
                </b-button>
            </div>

        </b-modal>
    </div>
</template>

<script>

import {
    BButton,
    BModal,
    VBModal,
    BForm,
    BFormInput,
    BFormGroup,
    BFormInvalidFeedback,
    BCol,
    BRow,
    BMedia,
    BAvatar,
    BFormCheckbox,
    BInputGroup,
    BLink,
    BInputGroupAppend,
} from "bootstrap-vue";
import Ripple from 'vue-ripple-directive'
import { ref, computed, onMounted, watch, reactive, defineEmits } from "@vue/composition-api";
import store from '@/store';
import * as helper from '@/libs/helpers';

export default {
    components: {
        BButton,
        BModal,
        BForm,
        BFormInput,
        BFormGroup,
        BFormInvalidFeedback,
        BCol,
        BRow,
        BMedia,
        BAvatar,
        BFormCheckbox,
        BInputGroup,
        BLink,
        BInputGroupAppend,
    },
    directives: {
        "b-modal": VBModal,
        Ripple,
    },
    props: {
        isActive: {
            type: Boolean,
            required: true,
            default: () => false,
        },       
    },
    setup(props, { emit }) {
        
        const loading = ref(false)
        const infoModalRef = ref(null);
        const selected = ref([])

        const closeModal = () => {
            infoModalRef.value.hide();          
            emit("close");
        };

        const setting = computed(() => {
            return store.getters['auth/getSettingPurchase']
        })

        const updateSettingPurchase = () => {
            store.dispatch('auth/UPDATE_SETTING_PURCHASE', setting.value)
            helper.success(`Configuración de compras actualizada con éxito`, '¡Éxito!');
            closeModal()
        }

        return {
            loading,
            infoModalRef,
            closeModal,
            setting,
            selected,

            //
            updateSettingPurchase
        }
    },
}
</script>
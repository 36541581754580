<template>
  <b-nav-item-dropdown right toggle-class="d-flex align-items-center dropdown-user-link" class="dropdown-user"
  @show="dropdownOpened" @hide="dropdownClosed">
    <template #button-content>
      <div class="d-sm-flex d-none user-nav">
        <p class="user-name font-weight-bolder mb-0">
          {{ userData.userName }}
        </p>        
        <span class="user-status">{{ userData.rol }} | {{ warehouseName }}</span>
      </div>
      <b-avatar size="40" :src="null" :text="avatarText(userData.userName)" variant="light-primary" badge class="badge-minimal"
        badge-variant="success">
        <feather-icon icon="UserIcon" size="22" />
      </b-avatar>
    </template>

    <!-- <b-dropdown-item :to="{ name: 'pages-profile' }" link-class="d-flex align-items-center">
      <feather-icon size="16" icon="UserIcon" class="mr-50" />
      <span>Profile</span>
    </b-dropdown-item>
    <b-dropdown-item :to="{ name: 'apps-email' }" link-class="d-flex align-items-center">
      <feather-icon size="16" icon="MailIcon" class="mr-50" />
      <span>Inbox</span>
    </b-dropdown-item>
    <b-dropdown-item :to="{ name: 'apps-todo' }" link-class="d-flex align-items-center">
      <feather-icon size="16" icon="CheckSquareIcon" class="mr-50" />
      <span>Task</span>
    </b-dropdown-item>
    <b-dropdown-item :to="{ name: 'apps-chat' }" link-class="d-flex align-items-center">
      <feather-icon size="16" icon="MessageSquareIcon" class="mr-50" />
      <span>Chat</span>
    </b-dropdown-item> -->

    <!-- <b-dropdown-divider /> -->

    <!-- <b-dropdown-item :to="{ name: 'pages-account-setting' }" link-class="d-flex align-items-center">
      <feather-icon size="16" icon="SettingsIcon" class="mr-50" />
      <span>Settings</span>
    </b-dropdown-item>
    <b-dropdown-item :to="{ name: 'pages-pricing' }" link-class="d-flex align-items-center">
      <feather-icon size="16" icon="CreditCardIcon" class="mr-50" />
      <span>Pricing</span>
    </b-dropdown-item>
    <b-dropdown-item :to="{ name: 'pages-faq' }" link-class="d-flex align-items-center">
      <feather-icon size="16" icon="HelpCircleIcon" class="mr-50" />
      <span>FAQ</span>
    </b-dropdown-item> -->
    <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
      <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
      <span>Logout</span>
    </b-dropdown-item></b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import store from "@/store"
import { mapActions } from 'vuex';

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
  },
  data() {
    return {
      // userData: store.getters['auth/getUser'], //JSON.parse(localStorage.getItem('userData')),
      avatarText,
    }
  },
  computed: {
    userData() {
      return store.getters['auth/getUser'];
    },
    warehouseName() {
      const warehouses = store.getters['auth/getWareHouses'];
      const warehouseId = store.getters['auth/getWarehouseId'];
      const finded = warehouses.find(warehouse => warehouse.almacen_Id === warehouseId);
      return finded ? finded.nombre : ' Almacenes';
    }
  },
  mounted() {
    
  },
  methods: {
    ...mapActions(['auth/UDPDATE_INPUT_FOCUS']),
    dropdownOpened() {
			store.commit('auth/SET_INPUT_FOCUS', false)
		},
		dropdownClosed() {
			store.commit('auth/SET_INPUT_FOCUS', true)
		},
    logout() {

      this.loading = true;
      store.dispatch("auth/LOGOUT").then(() => {
        console.log('logout')
      }).catch((error) => {
        this.loading = false;
      });
    },
  },
}
</script>
